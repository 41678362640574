import React from 'react';

const LineShareButton = ({ url, children, onClick, ...rest }) => {
  const openShareWindow = (e) => {
    onClick(e);
    window.open(`https://social-plugins.line.me/lineit/share?url=${url}`, '_blank', 'toolbar=no,location=no,status=no,menubar=no,width=500,height=600');
  };
  return (
    <button onClick={openShareWindow} style={{ border: 0, verticalAlign: 'top', background: 'transparent', margin: 0, padding: 0 }} {...rest}>
      {children}
    </button>
  );
}

export default LineShareButton;
