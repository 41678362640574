import React, { useEffect } from 'react';
import scriptLoader from 'react-async-script-loader';

const KakaotalkShareButton = ({ onClick, isScriptLoaded, isScriptLoadSucceed, url, templateId, templateArgs, children, ...rest }) => {
  useEffect(() => {
    if (isScriptLoaded && isScriptLoadSucceed) {
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init(process.env.GATSBY_KAKAO_KEY);
      }
    }
    return () => {
      window.Kakao.cleanup();
    };
  }, [isScriptLoaded, isScriptLoadSucceed]);
  return (
    <button style={{ border: 0, verticalAlign: 'top', background: 'transparent', margin: 0, padding: 0 }} onClick={(e) => {
      window.Kakao.Link.sendScrap({
        requestUrl: url,
        templateId,
        templateArgs
      });
      onClick(e);
    }}>
      {children}
    </button>
  );
}

const Wrapped = scriptLoader(
  [
    'https://developers.kakao.com/sdk/js/kakao.min.js'
  ]
)(KakaotalkShareButton);

export default Wrapped;
