import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Modal from 'react-modal';
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../Button';

Modal.setAppElement('#___gatsby');

const CouponModal = ({ isOpen, onRequestClose, siteData, style }) => (
  <Modal 
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={style}
  >
    <div style={{ display: 'flex', flexDirection: 'column', margin: '0 auto', width: '100%', height: '100%' }}>
      <a href="#" onClick={onRequestClose} style={{ textAlign: 'right', display: 'block' }}>
        <StaticImage 
          loading="eager"
          src="../../images/btn-close.png" 
          srcSet="../../images/btn-close@3x.png 3x, ../../images/btn-close@2x.png 2x" 
          placeholder="none"
          alt="" 
        />
      </a>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ 
          background: 'linear-gradient(148.1deg, #FFC61A 4.37%, #FF9A02 100%)',
          borderRadius: '22px',
          minWidth: '290px'
        }}>
          <div style={{ padding: '0 15px' }}>
            <div style={{ position: 'relative', top: '-62px', marginBottom: '-88px' }}>
            <StaticImage 
              loading="eager"
              placeholder="none"
              src="../../images/img-coupon-crystal@3x.png" 
              srcSet="../../images/img-coupon-crystal@3x.png 3x, ../../images/img-coupon-crystal@2x.png 2x" 
              style={{ width: '150px' }}
              alt="" 
            />
            </div>
            <p style={{
              fontSize: '25px',
              marginBottom: '12px',
              fontWeight: 900,
              textShadow: '0px 0px 10px rgba(255, 255, 255, 0.25), 0px 5px 15px rgba(189, 116, 0, 0.5)',
            }}>
              {siteData.couponName}
            </p>
            <p style={{
              fontWeight: 700,
            }}>
              {siteData.couponTitle}
            </p>
            <p style={{
              fontWeight: 700,
            }}>
              {siteData.couponDescription}
            </p>
            <p style={{ margin: '15px 0', fontWeight: 700 }}>
              <span className="diamond"> </span> {siteData.couponCodeText} <span className="diamond"> </span>
            </p>

            <div style={{ background: '#fff', borderRadius: '22px', padding: 0, margin: '10px 0 20px 0' }}>
              <p style={{ padding: '10px 0', color: '#141414', fontSize: '22px', fontWeight: 900 }}>
                {siteData.couponCode}
              </p>
              <p>
                <CopyToClipboard text={siteData.couponCode} onCopy={() => alert(siteData.copySuccessText)}>
                  <Button style={{
                    background: 'linear-gradient(360deg, #00D587 0%, #00CFB9 100%)',
                    borderRadius: '15px',
                    margin: 0,
                    padding: '16px 0',
                    fontSize: '16px',
                  }}>{siteData.couponCodeCopyText}</Button>
                </CopyToClipboard>
              </p>
            </div>
          </div>
          <div style={{ background: '#fff', padding: '15px', color: '#141414', borderRadius: '0 0 22px 22px' }}>
            <p style={{ color: '#FF9A03', fontSize: '14px', fontWeight: 700, marginBottom: '5px' }}>
              {siteData.couponRegisterDurationText}
            </p>
            <p style={{ fontSize: '14px', fontWeight: 700 }}>
              {siteData.couponRegisterDurationRangeText.couponRegisterDurationRangeText}
            </p>
            <p>
              <Button style={{ height: '75px', fontSize: '25px' }} onClick={() => window.open(siteData.couponRegisterUrl, '_blank')}>
                {siteData.couponRegisterText}
              </Button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default CouponModal;
